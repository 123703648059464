






























import { Component, Vue } from "vue-property-decorator";
import { PostBookLog } from "@/request/check";
import MainHeader from "@/components/main-header/main-header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends Vue {
  private id: any = "";
  private data: any = {};
  private getData() {
    const params: any = {
      id: this.id,
    };
    PostBookLog(this, params).then((res: any) => {
      this.data = res;
    });
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
  }
}
